<template>
	<div>
		<div class="headerbox">
			<el-row :gutter="20">
				<el-col :span="8">
					<img :src="talentData.image" class="headimg"  @error="itemError(talentData)"/>
				</el-col>
				<el-col :span="16">
					<el-row style="text-align: left;">
						<el-col :span="24">
							<span class="spantitle">{{talentData.name}}</span>
						</el-col>
						<el-col :span="24" style="margin-top: 25px;">
							<span class="spantimes">2284次播放</span>
						</el-col>
						<el-col :span="24" style="margin-top: 25px;">
							<span class="spanteacher">主讲老师：{{talentData.teacher}}</span>
						</el-col>
						<el-col :span="24" style="margin-top: 25px;">
							<span class="spanteacher">总时长：{{talentData.totalTime}}</span>
						</el-col>
						<el-col :span="24" style="margin-top: 25px;">
							<span class="spanteacher">观看有效期：365天</span>
						</el-col>
						<el-col :span="24" style="margin-top: 110px;">
							<el-button class="btn" @click="toExpert()">前往观看</el-button>
						</el-col>
					</el-row>
				</el-col>
			</el-row>
		</div>
		<div class="contentbox">
			<el-tabs style="margin-top: 20px;" v-model="activeName" @tab-click="handleClick()">
			    <el-tab-pane label="课程介绍" name="first" style="text-align: left;" v-html="talentData.introduction"></el-tab-pane>
			    <el-tab-pane style="text-align: left;" label="课程章节" name="second">
					<el-row>
						<el-col v-for="(item,index) in activities" :key="index">
							<p class="pname">{{index+1}}{{item.lessonName}}</p>
							<p class="ptimestamp">02:15:30</p>
						</el-col>
					</el-row>
				</el-tab-pane>
			</el-tabs>
		</div>
	</div>
</template>

<script>
import { baseURL } from "@/utils/request/apis";
import defaultImage from "@/assets/images/home/default_companyLogo.png"
export default {
	name: "ExpertInfo",
	data() {
		return {
			id:'',
			url:{
				getLzCourseUrl:"/lz_course/lzCourse/queryById",
			},
			talentData:{},
			activeName:"first",
			activities: [],
      defaultImage
		}
	},
	methods:{
		itemError(item){
      item.image = this.defaultImage;
		},
		// 获取基本信息
		getTalentData(){
			this.$http.get(baseURL+this.url.getLzCourseUrl, { id: this.id}).then((res) => {
				// console.log(res)
				this.talentData = res.result;
				this.talentData.image = baseURL + '/' + this.talentData.image;
				this.activities = JSON.parse(this.talentData.lessonJson);
			});
		},
		handleClick(tab, event) {
			// console.log(tab, event);
		},
		toExpert(){
			const url = this.$router.push({ name: 'TPExpertView' , query: { id: this.id }});
		}
	},
	created() {
		this.id = this.$route.query.id;
		this.getTalentData();
	}
}
</script>

<style scoped>
.headerbox {
	width: 1200px;
	padding: 20px;
	border-radius: 30px;
	background-color: #FFFFFF;
	margin:20px auto;
}
.headimg {
	width: 100%;
	height: 350px;
	border-radius: 20px;
}
.spantitle {
	font-family: 'PingFang SC';
	font-style: normal;
	font-weight: 800;
	font-size: 24px;
}
.spantimes {
	font-family: 'PingFang SC';
	font-style: normal;
	font-weight: 200;
	font-size: 12px;
	padding-top: 5px;
	padding-bottom: 5px;
	padding-left: 10px;
	padding-right: 10px;
	text-align: center;
}
.spanteacher {
	font-family: 'PingFang SC';
	font-style: normal;
	font-weight: 400;
	font-size: 13px;
	padding-top: 5px;
	padding-bottom: 5px;
}
.btn {
	width: 120px;
	background-color: #00CF97;
	color: #FFFFFF;
}
.contentbox {
	width: 1200px;
	padding: 20px;
	border-radius: 30px;
	background-color: #FFFFFF;
	margin:20px auto;
}
.pname {
	font-family: 'PingFang SC';
	font-style: normal;
	font-weight: 800;
	font-size: 16px;
}
.ptimestamp {
	font-family: 'PingFang SC';
	font-style: normal;
	font-weight: 200;
	font-size: 12px;
}
</style>